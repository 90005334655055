<template>
  <div id="service_predictor" class="bg-transparent">
    <div class="container-fluid p-3">
      <div class="row" id="operators">
        <div class="col-md-8">
          <div class="title">Find ISPs</div>
          <div class="form-group">
            <div class="form-check form-check-inline">
              <input
                type="radio"
                name="remoteNodeRadioOptions"
                class="form-check-input"
                v-model="findByAddress"
                value="true"
                id="findByAddress"
                @click="inputToogle('address')"
              /><label class="form-check-label" for="findByAddress"
                >Address</label
              >
            </div>
            <div class="form-check form-check-inline">
              <input
                type="radio"
                name="remoteNodeRadioOptions"
                class="form-check-input"
                v-model="findByState"
                value="true"
                id="findByState"
                @click="inputToogle('state')"
              />
              <label class="form-check-label" for="findByState">State</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                type="radio"
                name="remoteNodeRadioOptions"
                class="form-check-input"
                v-model="findByCountry"
                value="true"
                id="findByCountry"
                @click="inputToogle('country')"
              />
              <label class="form-check-label" for="findByCountry"
                >Country</label
              >
            </div>
          </div>
          <div
            class="form-group input-group mb-3"
            v-if="inputToggleValue == 'address'"
          >
            <input
              type="text"
              name="address"
              class="form-control"
              placeholder="630 Alder Drive, Milpitas, CA 95035"
              v-model="address"
              :class="addressError ? 'border-danger' : ''"
            />
            <span class="input-group-text marker-bg-color"
              ><i class="fa fa-map-marker" aria-hidden="true"></i>
            </span>
          </div>
          <div class="form-group mb-3" v-if="inputToggleValue == 'state'">
            <select
              v-model="selectedState"
              id="state"
              class="form-select"
              :class="stateError ? 'border-danger' : ''"
            >
              <option value="">Select a state</option>
              <option
                v-for="state in stateList"
                :key="state.abbreviation"
                :value="state.name"
              >
                {{ state.name }}
              </option>
            </select>
          </div>
          <div
            class="form-group mb-3"
            v-if="inputToggleValue == 'country' && countryList.length > 0"
          >
            <select
              v-model="selectedCountry"
              id="country"
              class="form-select"
              :class="countryError ? 'border-danger' : ''"
            >
              <option value="">Select a country</option>
              <option v-for="country in countryList" :value="country">
                {{ country }}
              </option>
            </select>
          </div>
          <button
            type="button"
            class="btn btn-operator mb-3"
            @click="findOperators"
          >
            Find ISPs
          </button>
        </div>
        <div class="col-md-12 border-top" v-if="successful">
          <div class="title pt-3">ISP Operator List</div>
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Name</th>
                <th>Website</th>
                <th>Phone</th>
              </tr>
            </thead>
            <tbody v-if="operatorList.length > 0">
              <tr v-for="operator in operatorList" :key="operator.name">
                <td>{{ operator.name != "" ? operator.name : "N/A" }}</td>
                <td>
                  <a
                    :href="formatWebsiteUrl(operator.website)"
                    target="_blank"
                    v-if="operator.website"
                  >
                    {{ operator.website }}
                  </a>
                  <span v-else>N/A</span>
                </td>
                <td>{{ operator.phone != "" ? operator.phone : "N/A" }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="3">No operators found at this location.</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      address: "",
      successful: false,
      addressError: false,
      stateError: false,
      countryError: false,
      inputToggleValue: "address",
      findByAddress: true,
      findByState: false,
      findByCountry: false,
      operatorList: [],
      apiResponse: "",
      selectedCountry: "",
      countryList: [],
      selectedState: "",
      stateList: [
        { name: "Alabama", abbreviation: "AL" },
        { name: "Alaska", abbreviation: "AK" },
        { name: "Arizona", abbreviation: "AZ" },
        { name: "Arkansas", abbreviation: "AR" },
        { name: "California", abbreviation: "CA" },
        { name: "Colorado", abbreviation: "CO" },
        { name: "Connecticut", abbreviation: "CT" },
        { name: "Delaware", abbreviation: "DE" },
        { name: "District Of Columbia", abbreviation: "DC" },
        { name: "Florida", abbreviation: "FL" },
        { name: "Georgia", abbreviation: "GA" },
        { name: "Hawaii", abbreviation: "HI" },
        { name: "Idaho", abbreviation: "ID" },
        { name: "Illinois", abbreviation: "IL" },
        { name: "Indiana", abbreviation: "IN" },
        { name: "Iowa", abbreviation: "IA" },
        { name: "Kansas", abbreviation: "KS" },
        { name: "Kentucky", abbreviation: "KY" },
        { name: "Louisiana", abbreviation: "LA" },
        { name: "Maine", abbreviation: "ME" },
        { name: "Maryland", abbreviation: "MD" },
        { name: "Massachusetts", abbreviation: "MA" },
        { name: "Michigan", abbreviation: "MI" },
        { name: "Minnesota", abbreviation: "MN" },
        { name: "Mississippi", abbreviation: "MS" },
        { name: "Missouri", abbreviation: "MO" },
        { name: "Montana", abbreviation: "MT" },
        { name: "Nebraska", abbreviation: "NE" },
        { name: "Nevada", abbreviation: "NV" },
        { name: "New Hampshire", abbreviation: "NH" },
        { name: "New Jersey", abbreviation: "NJ" },
        { name: "New Mexico", abbreviation: "NM" },
        { name: "New York", abbreviation: "NY" },
        { name: "North Carolina", abbreviation: "NC" },
        { name: "North Dakota", abbreviation: "ND" },
        { name: "Ohio", abbreviation: "OH" },
        { name: "Oklahoma", abbreviation: "OK" },
        { name: "Oregon", abbreviation: "OR" },
        { name: "Pennsylvania", abbreviation: "PA" },
        { name: "Rhode Island", abbreviation: "RI" },
        { name: "South Carolina", abbreviation: "SC" },
        { name: "South Dakota", abbreviation: "SD" },
        { name: "Tennessee", abbreviation: "TN" },
        { name: "Texas", abbreviation: "TX" },
        { name: "Utah", abbreviation: "UT" },
        { name: "Vermont", abbreviation: "VT" },
        { name: "Virginia", abbreviation: "VA" },
        { name: "Washington", abbreviation: "WA" },
        { name: "West Virginia", abbreviation: "WV" },
        { name: "Wisconsin", abbreviation: "WI" },
        { name: "Wyoming", abbreviation: "WY" },
      ],
    };
  },
  mounted() {
    this.loadCountryList();
  },
  methods: {
    inputToogle(val) {
      this.inputToggleValue = val;
      this.addressError = false;
      this.stateError = false;
      this.countryError = false;
      this.address = "";
      this.selectedState = "";
      this.selectedCountry = "";
    },
    isFormValid() {
      let formValid = true;
      if (this.address == "" && this.inputToggleValue == "address") {
        formValid = false;
      }
      if (this.selectedState == "" && this.inputToggleValue == "state") {
        formValid = false;
      }
      if (this.selectedCountry == "" && this.inputToggleValue == "country") {
        formValid = false;
      }
      return formValid;
    },
    formatWebsiteUrl(website) {
      const pattern = /https?:\/\//i;
      if (pattern.test(website)) {
        return website;
      }
      return `//${website}`;
    },
    loadCountryList() {
      this.countryList = [];

      let loader = this.$loading.show({
        canCancel: true,
        color: "#0367a9",
      });

      const postUrl =
        "https://tcc-network-planning.uw.r.appspot.com/v0/find/operators/country/list";

      const requestData = {
        post_url: postUrl,
      };

      try {
        this.$axios
          .post("services", requestData)
          .then((response) => {
            loader.hide();
            if (response.data.detail) {
              this.apiResponse = response.data.detail;
            } else if (response.data.region_list) {
              this.countryList = response.data.region_list;
            }
          })
          .catch(() => {
            loader.hide();
            console.log("Error");
          });
      } catch (error) {
        loader.hide();
        console.error("Error:", error);
      }
    },
    findOperators() {
      this.addressError = false;
      this.stateError = false;
      this.countryError = false;
      if (this.isFormValid()) {
        this.operatorList = [];

        let loader = this.$loading.show({
          canCancel: true,
          color: "#0367a9",
        });

        let postUrl =
          "https://tcc-network-planning.uw.r.appspot.com/v0/find/operators";

        if (this.address != "") {
          postUrl += "/" + encodeURIComponent(this.address);
        }
        if (this.selectedState != "") {
          postUrl += "/state/" + encodeURIComponent(this.selectedState);
        }
        if (this.selectedCountry != "") {
          postUrl += "/country/" + encodeURIComponent(this.selectedCountry);
        }
        // Define the request data
        const requestData = {
          post_url: postUrl,
        };

        try {
          this.$axios
            .post("services", requestData)
            .then((response) => {
              this.successful = true;
              loader.hide();

              if (response.data.detail) {
                this.apiResponse = response.data.detail;
              } else if (response.data.operator_list) {
                this.operatorList = response.data.operator_list;
              } else {
                this.successful = false;
              }
            })
            .catch(() => {
              loader.hide();
              console.log("Error");
            });
        } catch (error) {
          loader.hide();
          console.error("Error:", error);
        }
      } else {
        this.addressError =
          this.address != "" && this.inputToggleValue == "address"
            ? false
            : true;
        this.stateError =
          this.selectedState != "" && this.inputToggleValue == "state"
            ? false
            : true;
        this.countryError =
          this.selectedCountry != "" && this.inputToggleValue == "country"
            ? false
            : true;
      }
    },
  },
};
</script>
